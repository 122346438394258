import { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { withZod } from "@remix-validated-form/with-zod";
import { redirectWithSuccess } from "remix-toast";
import { z } from "zod";
import { getCart, removeItemFromCart } from "~/graphql/cart.server";
import { customerLogin } from "~/graphql/customer/login.server";
import { customerMergeCarts } from "~/graphql/customer/merge_carts.server";
import {
  getCustomerData,
  getCustomerProfile,
  setCustomerImage,
} from "~/graphql/customer/profile.server";
import { customerLoggedIn } from "~/servers/authorization.server";
import commerce from "~/servers/commerce.server";
import { getSession } from "~/servers/session.server";
import { urqlAuth, urqlInit } from "~/servers/urql.server";
import { calculateTimeDifferenceInSeconds, graphqlHeader } from "~/utils/utils";

export const loginValidator = withZod(
  z.object({
    username: z
      .string()
      .min(1, {
        message: "Бонус карт / Утасны дугаар оруулна уу!",
      })
      .regex(/^[0-9]*$/, {
        message: "Бонус карт / Утасны дугаар оруулна уу!",
      }),
    password: z.string().min(1, { message: "Нууц үгээ оруулна уу!" }),
  })
);

export async function action({ request, params }: ActionFunctionArgs) {
  let [formData, session] = await Promise.all([
    request.formData(),
    getSession(request, params),
  ]);

  const formParam = Object.fromEntries(formData);
  let redirectTo = formParam.redirectTo?.toString() || "/";
  const username = formParam.username.toString();
  const password = formParam.password.toString();
  const shipmentMethod = session.getShippingMethod() || "neoshipping";
  let isGoToIncompleteProfile = false;

  let message = "Нэвтрэхэд алдаа гарлаа. Дахин оролдоно уу!";

  /*
    Зочин хэрэглэгч нэвтэрсний дараа сагсуудыг нь merge хийнэ. Үүний тулд дараах
    алхамуудыг авч хэрэгжүүллээ. Үүнд:
      1. Зочин хэрэглэгч cartId байхгүй бол шинээр үүсгэх
      2. Зочин хэрэглэгчийг нэвтрүүлэх
      3. ApolloSdk-н authentication token-г тохируулах
      4. Хэрэглэгчийн cartid-г авахын тулд createCart-г дуудна
      5. Сагсуудыг merge хийх
  */

  try {
    //! Step 1
    let sourceCartId = session.getCartId();
    const headers = graphqlHeader(session);

    const visitorSdk = urqlInit({
      headers: headers,
    });

    if (!sourceCartId) {
      sourceCartId = await commerce.createMagentoCart(visitorSdk);
    }

    //! Step 2
    const clStartDate = Date.now();
    const raw = await customerLogin(visitorSdk, username, password);
    const clEndDate = Date.now();
    console.log(
      `================= CUSTOMER LOGIN: ${calculateTimeDifferenceInSeconds(
        clStartDate,
        clEndDate
      )}`
    );
    const customerData = raw.data["generateCustomerToken"];
    if (!customerData) {
      return json({
        message: raw.error?.graphQLErrors[0]?.message || message,
      });
    }
    const magentoToken = customerData["token"];
    const bonusNumber = customerData["cardId"];
    const loyaltyToken = customerData["loyaltytoken"];
    console.log(`------- magentoToken: ${magentoToken}`);
    console.log(`------- bonusNumber: ${bonusNumber}`);
    console.log(`------- loyaltyToken: ${loyaltyToken}`);
    console.log(`------- redirectTo: ${redirectTo}`);
    if (magentoToken && bonusNumber && loyaltyToken) {
      let headers = { authorization: `Bearer ${magentoToken}` };
      const customerSdk = urqlAuth({
        headers: headers,
      });

      const gcpStartDate = Date.now();
      const profile = await getCustomerProfile(
        customerSdk,
        bonusNumber,
        loyaltyToken
      );

      const gcpEndDate = Date.now();
      console.log(
        `================= GET CUSTOMER PROFILE: ${calculateTimeDifferenceInSeconds(
          gcpStartDate,
          gcpEndDate
        )}`
      );
      const gcdStartDate = Date.now();
      const rawCustomerData = await getCustomerData(customerSdk);
      const gcdEndDate = Date.now();
      console.log(
        `================= GET CUSTOMER DATA: ${calculateTimeDifferenceInSeconds(
          gcdStartDate,
          gcdEndDate
        )}`
      );

      const fullName = profile.data.loyaltydata["full_name"];
      const email = profile.data.loyaltydata["email"];
      const gender = profile.data.loyaltydata["customerGender"];
      const isProfileConfirmed =
        profile.data.loyaltydata["isConfirmInformation"];
      // const isIdPhoneUpdated = profile.data.loyaltydata["isIdPhoneUpdated"] === "1";
      const isConfirmFirst = profile.data.loyaltydata["isConfirmFirst"];
      const isPasswordChanged = profile.data.loyaltydata["isPasswordChanged"];
      const customerImage = rawCustomerData.data?.customer?.customer_image
        ? rawCustomerData.data?.customer?.customer_image
        : gender === "M"
        ? "avatar-1"
        : gender == "F"
        ? "avatar-4"
        : null;
      const customerWishlistId =
        rawCustomerData.data?.customer?.wishlist.id || 0;
      if (rawCustomerData.data?.customer?.customer_image) {
        const sciStartDate = Date.now();
        await setCustomerImage(customerSdk, customerImage, email);
        const sciEndDate = Date.now();
        console.log(
          `================= SET CUSTOMER IMAGE: ${calculateTimeDifferenceInSeconds(
            sciStartDate,
            sciEndDate
          )}`
        );
      }

      //! Merge carts
      //! Step 4.
      // const dcStartDate = Date.now();
      let destinationCartId = await commerce.createMagentoCart(customerSdk);
      // const dcEndDate = Date.now();
      // console.log(`================= CREATE DESTINATION CART: ${calculateTimeDifferenceInSeconds(dcStartDate, dcEndDate)}`);

      //! Incomplete profile
      if (email == "") {
        redirectTo = "/incomplete-profile";
        isGoToIncompleteProfile = true;
      }

      //! change password
      // if (isPasswordChanged !== "1" && !isGoToIncompleteProfile) {
      //   redirectTo = "/incomplete-profile/change-password";
      // }

      //! Step 5.
      // const cmcStartDate = Date.now();
      await customerMergeCarts(customerSdk, sourceCartId, destinationCartId);
      // const cmcEndDate = Date.now();
      // console.log(`================= CUSTOMER MERGE CARTS: ${calculateTimeDifferenceInSeconds(cmcStartDate, cmcEndDate)}`);

      session.setCustomerData(
        magentoToken,
        bonusNumber,
        loyaltyToken,
        fullName,
        customerImage,
        customerWishlistId
      );

      session.setCartId(destinationCartId);
      session.setMessage("Амжилттай нэвтэрлээ", "success");
      session.setShippingMethod(shipmentMethod);

      return redirectWithSuccess(redirectTo, "Та амжилттай нэвтэрлээ.", {
        headers: {
          "Set-Cookie": await session.commitSession(),
        },
      });
    }

    // ! Login Failed
  } catch (error) {
    console.log("------- ERROR [10]");
    console.error(error);
  }

  // ! Login Failed
  return json({ message: message });
}

export async function loader({ request, params }: LoaderFunctionArgs) {
  let [session] = await Promise.all([getSession(request, params)]);

  await customerLoggedIn(session);

  const message = session.get("upointMessage") || "";
  session.unset("upointMessage");

  return json({ message: message });
}
